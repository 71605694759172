import React from 'react'

class Lightbox extends React.Component {

    render() {

        return (

            <div className={ 'lightbox' + ( this.props.show ? ' visible' : '' ) }>

                <div className='content'>{ this.props.children }</div>

                <div className='overlay' onClick={ () => this.props.onClose && this.props.onClose() } />

            </div>

        )

    }

}

export default Lightbox