import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Badge, Button, Card, Col, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import MultiSelect from '../controls/MultiSelect'
import { getRefereeFullName, getRefereeShortName, getStyle } from '../../util'
import { relationTypes } from '../../util/enum'
import { getScreenWidth } from '../../util/dom'
import MultiDateInput from '../controls/MultiDateInput'
import { getCustomer } from '../../util/customer'

class RefereeInfoCard extends React.Component {

    #hiddenFields

    constructor( props ) {

        super( props )

        this.state = {

            notesModalOpen: false,

        }

        this.#hiddenFields = getCustomer().hiddenScheduleUserFields || []

    }

    getAssignmentStatistics( e ) {

        const value  = this.getValue( e )
        const detail = []
          let total  = this.props.referee ? 0 : null

        if ( value ) {

            detail.push( <div className='mt-1 border-top pb-1' key='divider' /> )

            Object.keys( value ).forEach( leagueId => {

                leagueId = Number( leagueId )

                const league = this.props.leagues && this.props.leagues.find( e => e.Id === leagueId )

                detail.push(

                    <Row key={ leagueId }>

                        <Col>{ league ? league.Name : leagueId }</Col>

                        <Col xs={ 4 }>{ value[ leagueId ] }{ this.getLocalChangesCount( leagueId ) }</Col>

                    </Row>

                )

                total += value[ leagueId ]

            } )

        }

        return { total, detail }

    }

    getLocalChangesCount( leagueId ) {

        if ( ! this.props.referee ) {

            return

        }

        let countMinus = 0
        let countPlus  = 0

        this.props.localChanges && Object.keys( this.props.localChanges ).forEach( gameId => Object.keys( this.props.localChanges[ gameId ] ).forEach( e => {

            const game = this.props.games && this.props.games.find( e => e.Id === Number( gameId ) )

            if ( game ) {

                const assignment  = game.MapRefereeGames && game.MapRefereeGames[ e ]
                const localChange = this.props.localChanges[ gameId ][ e ]

                if ( assignment && assignment.RefereeId !== localChange.RefereeId ) {

                    if ( assignment.RefereeId === this.props.referee.Id ) {

                        ( ! leagueId || game.leagueId === leagueId ) && countMinus++;

                    } else if ( localChange.RefereeId === this.props.referee.Id ) {

                        ( ! leagueId || game.leagueId === leagueId ) && countPlus++;

                    }

                }

            }

        } ) )

        return (

            countPlus || countMinus ?

                <>

                    { countPlus  ? <span className='text-muted'>+{ countPlus  }</span> : null }

                    { countMinus ? <span className='text-muted'>-{ countMinus }</span> : null }

                </>

            :

                null

        )

    }

    getNotes() {

        return (

            <>

                <div className='text-nowrap text-truncate fw-700'>{ this.getValue( 'LeagueManagerNote' ) }</div>
                <div className='text-nowrap text-truncate'>{ this.getValue( 'RefereeNote' ) }</div>

            </>

        )

    }

    getNotesLabel() {

        return (

            <>

                { _( 'Info' ) }

                { this.hasNotes() ? <Icon icon='info-circle' className='ms-1 cursor-pointer' onClick={ () => this.toggleNotesModal() } /> : '' }

            </>

        )

    }

    getPopover( header, body ) {

        return (

            <Popover>

                <Popover.Header>{ header }</Popover.Header>

                <Popover.Body>{ body }</Popover.Body>

            </Popover>

        )

    }

    getRefereeAbsences() {

        if ( ! this.props.referee ) {

            return

        }

        const absences = this.getValue( 'Absences' )

        return absences && absences.map( e => { return { ...e, bg: e.Reason && this.props.absenceReasons ? getStyle( this.props.absenceReasons.findIndex( f => f.Id === e.Reason.Id ) ) : null, Type: e.Reason ? e.Reason.Id : 0 } } )

    }

    getRefereeBans() {

        if ( ! this.props.referee ) {

            return

        }

        const refereeBans = this.getValue( 'RefereeBans' )

        return refereeBans && refereeBans.map( e => {

            return {

                label: e.Team ? e.Team.ShortName : '???',
                title: e.Team ? e.Team.Name      : _( 'Unbekanntes Team' ),

            }

        } )

    }

    getRefereeRelations() {

        if ( ! this.props.referee ) {

            return

        }

        const refereeRelations = this.getValue( 'RefereeRelations' )

        return refereeRelations && refereeRelations.map( e => {

            const refereeIndex = e.Referee1 && e.Referee1.Id === this.props.referee.Id ? 2 : e.Referee2 && e.Referee2.Id === this.props.referee.Id ? 1 : -1

            return {

                className: 'relation-type-' + e.RelationType,
                label:     <>{ e.RelationType === 3 && <Icon icon='ban' /> } { getRefereeShortName( e[ 'Referee' + refereeIndex ] ) }</>,
                title:     getRefereeFullName( e[ 'Referee' + refereeIndex ] ) + ' (' + relationTypes[ e.RelationType ] + ')',

            }

        } )

    }

    getValue( e ) {

        return this.props.referee ? this.props.referee[ e ] : ''

    }

    hasNotes() {

        return this.getValue( 'LeagueManagerNote' ) || this.getValue( 'RefereeNote' )

    }

    toggleNotesModal() {

        this.setState( { notesModalOpen: ! this.state.notesModalOpen }, () => this.props.onNotesOpen( this.state.notesModalOpen ) )

    }

    renderCountRow( label, value, popoverHeader, popoverBody, name ) {

        if ( name && this.#hiddenFields.includes( name ) ) {

            return

        }

        return (

            <Row className='mb-2'>

                <Col lg={ 8 } md={ 5 } className='fw-700'>

                    { label }

                    <OverlayTrigger placement={ getScreenWidth() <= 991 ? 'right' : 'left' } overlay={ this.getPopover( popoverHeader, popoverBody ) }>

                        <span><Icon icon='info-circle' className='ms-1 cursor-pointer' /></span>

                    </OverlayTrigger>

                </Col>

                <Col lg={ 4 } md={ 7 }>{ value }{ this.getLocalChangesCount() }</Col>

            </Row>

        )

    }

    renderRow( label, value, name ) {

        if ( name && this.#hiddenFields.includes( name ) ) {

            return

        }

        return (

            <Row className='mb-2'>

                <Col md={ 5 } className='fw-700'>{ label }</Col>
                <Col md={ 7 }>{ value }</Col>

            </Row>

        )

    }

    renderWithLineBreaks( e ) {

        return e ? e.split( '\n' ).map( ( e, i ) => <React.Fragment key={ i }>{ e }<br /></React.Fragment> ) : ''

    }

    render() {

        const leagueManagerNote = this.getValue( 'LeagueManagerNote' )
        const leagueCount       = this.getAssignmentStatistics( 'LeagueCnt' )
        const allSeasonCount    = this.getAssignmentStatistics( 'AllSeasonCnt' )
        const allLeaguesCount   = this.getAssignmentStatistics( 'AllLeaguesCnt' )

        return (

            <>

                <Card className={ 'mb-2 referee-info-card' + ( this.props.className ? ' ' + this.props.className : '' ) }>

                    <Card.Body>

                        <Row>

                            <Col lg={ 7 }>

                                { this.renderRow( _( 'Name'         ),  getRefereeFullName( this.props.referee )                                                ) }

                                { this.renderRow( _( 'Wohnort'      ),  this.props.referee && this.props.referee.Address ? this.props.referee.Address.City : '' ) }

                                { this.renderRow( _( 'Coach Rating' ),  this.props.referee ? <Badge bg='success'>{ this.props.referee.Rating }</Badge> : '', 'coachRating' ) }

                            </Col>

                            <Col lg={ 5 }>

                                { this.renderCountRow( _( 'Saison' ), allSeasonCount.total, _( 'Anzahl Saison' ), <>{ _( 'Anzahl der Spiele der aktuellen Saison, unabhängig vom ausgewählten Zeitraum und den ausgewählten Ligen.' ) }{ allSeasonCount.detail }</> ) }

                                { this.renderCountRow( _( 'Liga' ), leagueCount.total, _( 'Anzahl Liga' ), <>{ _( 'Anzahl der Spiele im ausgewählten Zeitraum und den ausgewählten Ligen.' ) }{ leagueCount.detail }</>, 'league' ) }

                                { this.renderCountRow( _( 'Alle Ligen' ), allLeaguesCount.total, _( 'Anzahl alle Ligen' ), <>{ _( 'Anzahl der Spiele in allen Ligen im ausgewählten Zeitraum.' ) }{ allLeaguesCount.detail }</>, 'allLeagues' ) }

                            </Col>

                        </Row>

                        <Row className='mb-1'>

                            <Col lg={ 3 } className='fw-700'>{ this.getNotesLabel() }</Col>
                            <Col>{ this.getNotes() }</Col>

                        </Row>

                        { ! this.#hiddenFields.includes( 'refereeBans' ) &&

                            <Row className='mb-1'>

                                <Col lg={ 3 } className='fw-700'>{ _( 'Geblockt' ) }</Col>
                                <Col><MultiSelect editable={ false } initialValue={ this.getRefereeBans() } standalone={ true } /></Col>

                            </Row>

                        }

                        { ! this.#hiddenFields.includes( 'refereeRelations' ) &&

                            <Row className='mb-1'>

                                <Col lg={ 3 } className='fw-700'>{ _( 'Partner' ) }</Col>
                                <Col><MultiSelect editable={ false } initialValue={ this.getRefereeRelations() } standalone={ true } /></Col>

                            </Row>

                        }

                        <Row className='mb-1'>

                            <Col lg={ 3 } className='fw-700'>{ _( 'Abwesend' ) }</Col>
                            <Col><MultiDateInput editable={ false } initialValue={ this.getRefereeAbsences() } standalone={ true } /></Col>

                        </Row>

                    </Card.Body>

                </Card>

                <Modal show={ this.state.notesModalOpen } onHide={ () => this.toggleNotesModal() } size='xl' centered scrollable>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        { leagueManagerNote ? <div className='border-bottom mb-2 pb-2'>{ this.renderWithLineBreaks( leagueManagerNote ) }</div> : '' }

                        <div>{ this.renderWithLineBreaks( this.getValue( 'RefereeNote' ) ) }</div>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.toggleNotesModal() } variant='secondary' className='flex-grow-1'>{ _( 'Schließen' ) }</Button>

                    </Modal.Footer>

                </Modal>

            </>

        )

    }

}

export default RefereeInfoCard