import React from 'react'
import { Button, ButtonGroup, FormControl, ListGroup, Modal } from 'react-bootstrap'
import { translate as _ } from '@morawadigital/skynet-framework'

class TreeSelect extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            modalOpen: false,
            value:     this.getValueFromProps(),

        }

    }

    apply() {

        this.setState( { modalOpen: false }, () => this.props.onChange( this.state.value ) )

    }

    cancel() {

        this.setState( { modalOpen: false, value: this.getValueFromProps() } )

    }

    getChildByValue( value ) {

        let ret = null

        this.props.options && this.props.options.forEach( item => {

            item.children.forEach( child => {

                if ( child.value === value ) {

                    ret = child

                }

            } )

        } )

        return ret

    }

    getDisplayValue() {

        const displayValue = []

        this.props.initialValue && this.props.initialValue.forEach( value => {

            const child = this.getChildByValue( value )

            displayValue.push( child ? child.label : value )

        } )

        return displayValue.join( ', ' )

    }

    getValueFromProps() {

        return this.props.initialValue ? [ ...this.props.initialValue ] : []

    }

    isSelected( e ) {

        return this.state.value.indexOf( e ) !== -1

    }

    selectAll() {

        const value = []

        this.props.options && this.props.options.forEach( item => item.children.forEach( child => value.push( child.value ) ) )

        this.setState( { value } )

    }

    selectNone() {

        this.setState( { value: [] } )

    }

    toggleItem( e ) {

        if ( this.isSelected( e ) ) {

            const value = [ ...this.state.value ]

            value.splice( value.indexOf( e ), 1 )

            this.setState( { value } )

        } else {

            this.setState( { value: [ ...this.state.value, e ] } )

        }

    }

    toggleModal() {

        this.setState( { modalOpen: ! this.state.modalOpen } )

    }

    toggleParent( item, anyChildSelected ) {

        const value = [ ...this.state.value ]

        item.children.forEach( child => {

            if ( anyChildSelected ) {

                const valueIndex = value.indexOf( child.value )

                valueIndex !== -1 && value.splice( valueIndex, 1 )

            } else {

                value.push( child.value )

            }

        } )

        this.setState( { value } )

    }

    renderItem( item, i ) {

        const ret                 = []
          let allChildrenSelected = !! item.children.length
          let anyChildSelected    = false

        item.children.forEach( ( child, childIndex ) => {

            const isSelected = this.isSelected( child.value )

            ret.push( <ListGroup.Item key={ i + '-' + childIndex } className='ps-5' onClick={ () => this.toggleItem( child.value ) } action active={ isSelected }>{ child.label }</ListGroup.Item> )

            if ( ! isSelected ) {

                allChildrenSelected = false

            } else {

                anyChildSelected = true

            }

        } )

        ret.unshift( <ListGroup.Item key={ i } className='fw-700' onClick={ () => this.toggleParent( item, anyChildSelected ) } action active={ allChildrenSelected }>{ item.label }</ListGroup.Item> )

        return ret

    }

    render() {

        return (

            <>

                <FormControl className='tree-select' readOnly onClick={ () => this.toggleModal() } value={ this.getDisplayValue() } />

                <Modal show={ this.state.modalOpen } onHide={ () => this.toggleModal() } centered scrollable>

                    <Modal.Header closeButton className='align-items-center'>

                        <ButtonGroup size='sm'>

                            <Button variant='outline-secondary' onClick={ () => this.selectAll()  }>{ _( 'Alle'  ) }</Button>
                            <Button variant='outline-secondary' onClick={ () => this.selectNone() }>{ _( 'Keine' ) }</Button>

                        </ButtonGroup>

                    </Modal.Header>

                    <Modal.Body>

                        { this.props.options &&

                            <ListGroup>

                                { this.props.options.map( ( e, i ) => this.renderItem( e, i ) ) }

                            </ListGroup>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.cancel() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.apply() }>{ _( 'Übernehmen' ) }</Button>

                    </Modal.Footer>

                </Modal>

            </>

        )

    }

}

export default TreeSelect