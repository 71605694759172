import { connect } from 'react-redux'
import Games from '../../components/pages/Games'

const mapStateToProps = state => {

    return {

        loading: state.app.loading,

    }

}

export default connect( mapStateToProps )( Games )