import React from 'react'
import { Alert, Badge, Button, Card, CardGroup, Col, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap'
import { Prompt, withRouter } from 'react-router-dom'
import { ajax, getDataUrl, Icon, formatDate, formatTime, translate as _ } from '@morawadigital/skynet-framework'
import Select from 'react-select'
import { getCoachResultStatusIcon, getRefereeFullName } from '../../util'
import RefereeBadge from '../elements/RefereeBadge'
import { coachResultStatus } from '../../util/enum'
import { COACH_RESULT_STATUS_PENDING, COACH_RESULT_STATUS_RATED, COACH_RESULT_STATUS_RELEASED, PRIV_SET_COACH_RESULT_STATUS } from '../../util/constants'
import { hasPrivilege } from '../../util/permissions'
import RefereeRating from '../elements/RefereeRating'
import { getCustomer } from '../../util/customer'

class CoachResult extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            backToCoachMessage:         '',
            backToCoachModalOpen:       false,
            coachResult:                null,
            coachResultCriteria:        {},
            coachResultFactorsNegative: [],
            coachResultFactorsPositive: [],
            coachResultStructure:       null,
            hasLoadingError:            false,
            hasSavingError:             false,
            hasUnsavedChanges:          false,
            isWorking:                  false,
            loading:                    false,
            setStatusErrorMessage:      null,
            setStatusSuccessMessage:    null,
            showSaveSuccessMessage:     false,

        }

        this.checkUnsavedChanges = this.checkUnsavedChanges.bind( this )

    }

    componentDidMount() {

        this.load()

        window.addEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    componentWillUnmount() {

        window.removeEventListener( 'beforeunload', this.checkUnsavedChanges )

    }

    changeCriterion( e ) {

        this.setState( { coachResultCriteria: { ...this.state.coachResultCriteria, [ e.target.dataset.id ]: Number( e.target.value ) }, hasUnsavedChanges: true } )

    }

    changeFactors( value, isPositive ) {

        this.setState( { [ isPositive ? 'coachResultFactorsPositive' : 'coachResultFactorsNegative' ]: value, hasUnsavedChanges: true } )

    }

    changeNote( e ) {

        this.setState( { coachResult: { ...this.state.coachResult, Note: e }, hasUnsavedChanges: true } )

    }

    checkUnsavedChanges( e ) {

        if ( this.state.hasUnsavedChanges ) {

            e.preventDefault()

            return e.returnValue = _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' )

        }

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    dismissSetStatusSuccessMessage() {

        this.setState( { setStatusSuccessMessage: null } )

    }

    dismissSettingStatusError() {

        this.setState( { setStatusErrorMessage: null } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    errorSettingStatus( setStatusErrorMessage ) {

        this.setState( { setStatusErrorMessage } )

    }

    forward( path, status, successMessage, errorMessage, data ) {

        this.setState( { isWorking: true }, () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Coaching/' + path ), { id: this.state.coachResult.Id, status, token: this.props.token, ...( data || {} ) }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { setStatusSuccessMessage: successMessage,  coachResult: { ...this.state.coachResult, Status: status } } ) : this.errorSettingStatus( errorMessage ) )
                .catch( () => this.errorSettingStatus( errorMessage ) )
                .finally( () => this.setState( { isWorking: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    load() {

        this.setState( { hasLoadingError: false, loading: true }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Coaching/getCoachResult',           name: 'coachResult',         data: { id: this.props.match.params.coachResultId } } ),
                this.loadItems( { url: 'api/Coaching/getCoachResultStructure',  name: 'coachResultStructure'                                                     } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => {

                    const coachResultCriteria = {}
                    const coachResultFactors  = { positive: [], negative: [] }

                    if ( this.state.coachResult ) {

                        this.state.coachResult.CriteraResults && this.state.coachResult.CriteraResults.forEach( e => { coachResultCriteria[ e.Name.Id ] = e.Value } )

                        this.state.coachResult.MapCoachResultFactors && this.state.coachResult.MapCoachResultFactors.forEach( e => coachResultFactors[ e.IsPositiv ? 'positive' : 'negative' ].push( { label: e.Name.Name, value: e.Name.Id } ) )

                    }

                    this.state.coachResultStructure.CoachCriteria.forEach( e => {

                        if ( ! ( e.Id in coachResultCriteria ) ) {

                            coachResultCriteria[ e.Id ] = 3

                        }

                    } )

                    this.setState( { loading: false, coachResultFactorsPositive: coachResultFactors.positive, coachResultFactorsNegative: coachResultFactors.negative, coachResultCriteria }, () => this.props.onToggleLoading( false ) )

                } )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token, ...( options.data || {} ) }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: e.Data.length ? e.Data[ 0 ] : null }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    print() {

        const form  = document.createElement( 'form'  )
        const id    = document.createElement( 'input' )
        const token = document.createElement( 'input' )

        form.target        = '_blank'
        form.method        = 'post'
        form.action        = getDataUrl( 'api/Coaching/printPDF' )
        form.style.display = 'none'
        id.name            = 'id'
        id.value           = this.state.coachResult.Id
        token.name         = 'token'
        token.value        = this.props.token

        document.body.append( form )

        form.append( id    )
        form.append( token )
        form.submit()
        form.remove()

    }

    save() {

        this.setState( { hasSavingError: false, isWorking: true, showSaveSuccessMessage: false }, () => {

            this.props.onToggleLoading( true )

            const criteria = []
            const factors  = []

            Object.keys( this.state.coachResultCriteria ).forEach( e => criteria.push( { CriteriaId: Number( e ), Value: this.state.coachResultCriteria[ e ] } ) )

            this.state.coachResultFactorsPositive.forEach( e => factors.push( { FactorId: e.value, IsPositive: true  } ) )
            this.state.coachResultFactorsNegative.forEach( e => factors.push( { FactorId: e.value, IsPositive: false } ) )

            const data = {

                id:                        this.state.coachResult.Id,
                criteriaResultsJson:       JSON.stringify( criteria ),
                mapCoachResultFactorsJson: JSON.stringify( factors ),
                note:                      this.state.coachResult.Note || '',
                token:                     this.props.token,

            }

            ajax( getDataUrl( 'api/Coaching/saveCoachResult' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { showSaveSuccessMessage: true, hasUnsavedChanges: false } ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isWorking: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    submitBackToCoach() {

        const backToCoachMessage = this.state.backToCoachMessage

        this.setState( { backToCoachMessage: '', backToCoachModalOpen: false }, () => {

            this.forward(
                'setStatus',
                COACH_RESULT_STATUS_PENDING,
                _( 'Evaluierung wurde zurück an den Coach gegeben.' ),
                _( 'Evaluierung konnte nicht zurück an den Coach gegeben werden.' ),
                { message: backToCoachMessage }
            )

        } )

    }

    toggleBackToCoachModal() {

        this.setState( { backToCoachModalOpen: ! this.state.backToCoachModalOpen } )

    }

    validateForm() {

        if ( ! this.state.coachResult.Note                  ) { return false }
        if ( ! this.state.coachResultFactorsPositive.length ) { return false }
        if ( ! this.state.coachResultFactorsNegative.length ) { return false }

        return true

    }

    renderCriteria() {

        return (

            <Card className='mb-4'>

                <Card.Body>

                    <Row className='coach-result-criteria-header'>

                        <Col xs={ 7 }></Col>
                        <Col xs={ 1 }>--</Col>
                        <Col xs={ 1 }>-</Col>
                        <Col xs={ 1 }>N</Col>
                        <Col xs={ 1 }>+</Col>
                        <Col xs={ 1 }>++</Col>

                    </Row>

                    { this.state.coachResultStructure.CoachCategories.map( ( e, i ) => this.renderCriteriaCategory( e, i ) ) }

                </Card.Body>

            </Card>

        )

    }

    renderCriteriaCategory( category, i ) {

        return (

            <React.Fragment key={ i }>

                <Row>

                    <Col>

                        <h3>{ category.Name }</h3>

                    </Col>

                </Row>

                { this.state.coachResultStructure.CoachCriteria.map( ( e, i ) => e.CoachResultCategory.Id === category.Id && this.renderCriterion( e, i ) ) }

                <Row className='border-bottom mb-3 pb-3'></Row>

            </React.Fragment>

        )

    }

    renderCriterion( criterion, i ) {

        return (

            <Row key={ i } className='mb-2'>

                <Col xs={ 7 }>{ criterion.Name }</Col>

                <Col xs={ 5 }>

                    <Form.Range
                        data-id={ criterion.Id }
                        disabled={ this.state.isWorking || this.state.coachResult.Status !== COACH_RESULT_STATUS_PENDING }
                        max='5'
                        min='1'
                        name={ 'criterion-' + criterion.Id }
                        onChange={ e => this.changeCriterion( e ) }
                        value={ ( criterion.Id in this.state.coachResultCriteria ) ? this.state.coachResultCriteria[ criterion.Id ] : 3 }
                    />

                </Col>

            </Row>

        )

    }

    renderFactors() {

        return (

            <Card className='mt-4'>

                <Card.Body>

                    <div className='mb-3'>

                        <Form.Label>{ _( 'Stärken' ) }</Form.Label>

                        <Select
                            isDisabled={ this.state.isWorking || this.state.coachResult.Status !== COACH_RESULT_STATUS_PENDING }
                            isMulti
                            noOptionsMessage={ () => _( 'Keine Optionen' ) }
                            onChange={ e => this.changeFactors( e, true ) }
                            options={ this.state.coachResultStructure.CoachFactors && this.state.coachResultStructure.CoachFactors.map( e => { return { value: e.Id, label: e.Name } } ) }
                            placeholder=''
                            value={ this.state.coachResultFactorsPositive }
                        />

                    </div>

                    <div className='mb-3'>

                        <Form.Label>{ _( 'Verbesserungspotenzial' ) }</Form.Label>

                        <Select
                            isDisabled={ this.state.isWorking || this.state.coachResult.Status !== COACH_RESULT_STATUS_PENDING }
                            isMulti
                            noOptionsMessage={ () => _( 'Keine Optionen' ) }
                            onChange={ e => this.changeFactors( e, false ) }
                            options={ this.state.coachResultStructure.CoachFactors && this.state.coachResultStructure.CoachFactors.map( e => { return { value: e.Id, label: e.Name } } ) }
                            placeholder=''
                            value={ this.state.coachResultFactorsNegative }
                        />

                    </div>

                </Card.Body>

            </Card>

        )

    }

    renderInfo() {

        return (

            <Card>

                <Card.Body>

                    <h4 className='text-center py-6 fs-1'>

                        { getRefereeFullName( this.state.coachResult.Referee ) }

                        <div className='fs-5'><Badge>{ this.state.coachResult.RefereeSystemJob.Shortname }</Badge></div>

                    </h4>

                    <CardGroup className='text-center'>

                        <Card>

                            <Card.Header>{ _( 'Status' ) }</Card.Header>

                            <Card.Body className='fs-6'>

                                <RefereeBadge
                                    className={ 'coach-result-status-' + this.state.coachResult.Status }
                                    displayName={ <>{ getCoachResultStatusIcon( this.state.coachResult.Status ) } { coachResultStatus[ this.state.coachResult.Status ] }</> }
                                />

                            </Card.Body>

                        </Card>

                        <Card>

                            <Card.Header>{ _( 'Spiel' ) }</Card.Header>

                            <Card.Body className='fs-5'>

                                { this.state.coachResult.Game.HomeTeam.Name } <small className='text-muted'>{ ' ' + _( 'vs.' ) + ' ' }</small> { this.state.coachResult.Game.AwayTeam.Name }

                            </Card.Body>

                        </Card>

                        <Card>

                            <Card.Header>{ _( 'Datum' ) }</Card.Header>

                            <Card.Body className='fs-5'>

                                { formatDate( this.state.coachResult.Game.ScheduleDate, { year: 'long', weekday: 'short' } ) } - { formatTime( this.state.coachResult.Game.ScheduleDate ) }

                            </Card.Body>

                        </Card>

                        <Card>

                            <Card.Header>{ _( 'Ort' ) }</Card.Header>

                            <Card.Body className='fs-5'>

                                { this.renderLocation() }

                            </Card.Body>

                        </Card>

                        <Card>

                            <Card.Header>{ _( 'Coach' ) }</Card.Header>

                            <Card.Body className='fs-5'>

                                { getRefereeFullName( this.state.coachResult.Coach ) }

                            </Card.Body>

                        </Card>

                        <Card>

                            <Card.Header>{ _( 'Rating' ) }</Card.Header>

                            <Card.Body className='fs-5'>

                                <RefereeRating rating={ this.state.coachResult.Rating } />

                            </Card.Body>

                        </Card>

                    </CardGroup>

                </Card.Body>

            </Card>

        )

    }

    renderLocation() {

        let location = ''

        if ( this.state.coachResult.Game.Location ) {

            if ( getCustomer().useLocationShortName ) {

                location = this.state.coachResult.Game.Location.Shortname

            } else {

                if ( this.state.coachResult.Game.Location.Address && this.state.coachResult.Game.Location.Address.City ) {

                    location = this.state.coachResult.Game.Location.Address.City

                } else if ( this.state.coachResult.Game.Location.Name ) {

                    location = this.state.coachResult.Game.Location.Name

                }

            }

            if ( this.state.coachResult.Game.Location.GoogleLink ) {

                location = <a href={ this.state.coachResult.Game.Location.GoogleLink } target='_blank' rel='noopener noreferrer' className='text-success'>{ location || 'Google Maps' }</a>

            }

        }

        return location

    }

    renderNotes() {

        return (

            <Card>

                <Card.Header>{ _( 'Bemerkungen' ) }</Card.Header>

                <Card.Body>

                    <Form.Control
                        as='textarea'
                        disabled={ this.state.isWorking || this.state.coachResult.Status !== COACH_RESULT_STATUS_PENDING }
                        onChange={ e => this.changeNote( e.target.value ) }
                        rows='8'
                        value={ this.state.coachResult.Note === null ? '' : this.state.coachResult.Note }
                    />

                </Card.Body>

            </Card>

        )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='list' className='subheader-icon' /> { _( 'Evaluierung' ) }

                    </h1>

                </div>

                {

                    this.state.loading ?

                        <p className='lead text-center'>

                            <Icon icon='spinner' spin /> { _( 'Daten werden geladen' ) }

                        </p>

                    : this.state.coachResult && this.state.coachResultStructure ?

                        <>

                            { this.state.coachResult.Status === COACH_RESULT_STATUS_PENDING && this.state.coachResult.Message &&

                                <Alert variant='info' className='my-4'>

                                    <p className='lead'><Icon icon='info-circle' /> { _( 'Diese Evaluierung wurde mit folgender Begründung von der Freigabe zurückgewiesen:' ) }</p>

                                    <blockquote className='blockquote'>"{ this.state.coachResult.Message }"</blockquote>

                                </Alert>

                            }

                            { this.renderInfo() }

                            <Row className='my-4'>

                                <Col xs={ 12 } lg={ 6 }>

                                    { this.renderCriteria() }

                                </Col>

                                <Col xs={ 12 } lg={ 6 }>

                                    { this.renderNotes() }

                                    { this.renderFactors() }

                                </Col>

                            </Row>

                            <Row>

                                <Col>

                                    {/* TODO Enable "Print PDF" button */}
                                    {/* <Button size='lg' className='mx-2' disabled={ this.state.isWorking || this.state.hasUnsavedChanges } onClick={ () => this.print() }>{ _( 'PDF drucken' ) }</Button> */}

                                    { this.state.coachResult.Status === COACH_RESULT_STATUS_PENDING &&

                                        <>

                                            <Button size='lg' className='mx-2' disabled={ this.state.isWorking } onClick={ () => this.save() }>{ _( 'Speichern' ) }</Button>

                                            <Button size='lg' className='mx-2' disabled={ this.state.isWorking || this.state.hasUnsavedChanges || ! this.validateForm() } onClick={ () => this.forward( 'toRelease', COACH_RESULT_STATUS_RATED, _( 'Evaluierung wurde zur Freigabe weitergeleitet.' ), _( 'Evaluierung konnte nicht zur Freigabe weitergeleitet werden.' ) ) }>{ _( 'Zur Freigabe' ) }</Button>

                                            { this.state.hasUnsavedChanges && <div className='m-2'><small><Icon icon='info-circle' /> { _( 'Zum Freigeben Daten speichern.' ) }</small></div> }

                                            { ! this.validateForm() && <div className='m-2'><small><Icon icon='info-circle' /> { _( 'Die Felder Bemerkungen, Stärken und Verbesserungspotenzial müssen ausgefüllt sein, um freigeben zu können.' ) }</small></div> }

                                        </>

                                    }

                                    { this.state.coachResult.Status === COACH_RESULT_STATUS_RATED && hasPrivilege( this.props.user, PRIV_SET_COACH_RESULT_STATUS ) &&

                                        <>

                                            <Button size='lg' className='mx-2' disabled={ this.state.isWorking } onClick={ () => this.toggleBackToCoachModal() }>{ _( 'Zurück an Coach' ) }</Button>

                                            <Button size='lg' className='mx-2' disabled={ this.state.isWorking } onClick={ () => this.forward( 'setStatus', COACH_RESULT_STATUS_RELEASED, _( 'Evaluierung wurde freigegeben.' ), _( 'Evaluierung konnte nicht freigegeben werden.' ) ) }>{ _( 'Freigeben' ) }</Button>

                                        </>

                                    }

                                </Col>

                            </Row>

                        </>


                    :

                        ''

                }

                <Modal show={ this.state.backToCoachModalOpen } onHide={ () => this.toggleBackToCoachModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        <p className='lead mb-5'>{ _( 'Die Evaluierung wird zur Bearbeitung zurück an den Coach gegeben. Sie können zur Begründung unterhalb eine Nachricht eingeben. Diese wird dem Coach bei der Evaluierung angezeigt.' ) }</p>

                        <Form.Group as={ Row } controlId='backToCoachMessage'>

                            <Form.Label column sm={ 3 }>{ _( 'Nachricht' ) }</Form.Label>

                            <Col sm={ 9 }>

                                <Form.Control value={ this.state.backToCoachMessage } onChange={ e => this.setState( { backToCoachMessage: e.target.value } ) } />

                            </Col>

                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.toggleBackToCoachModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.submitBackToCoach() }>{ _( 'Absenden' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSettingStatusError() } show={ this.state.setStatusErrorMessage !== null }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ this.state.setStatusErrorMessage }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSetStatusSuccessMessage() } show={ this.state.setStatusSuccessMessage !== null }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { this.state.setStatusSuccessMessage }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

                <Prompt
                    message={ _( 'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.' ) }
                    when={ this.state.hasUnsavedChanges }
                />

            </>

        )

    }

}

export default withRouter( CoachResult )