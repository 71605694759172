import { connect } from 'react-redux'
import CalendarModal from '../../components/elements/CalendarModal'

const mapStateToProps = state => {

    return {

        token: state.auth.token,
        user:  state.auth.user,

    }

}

export default connect( mapStateToProps )( CalendarModal )