import { connect } from 'react-redux'
import UrgentChanges from '../../components/elements/UrgentChanges'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

export default connect( mapStateToProps )( UrgentChanges )