import React from 'react'
import { Badge, Card, Col, Placeholder, Row } from 'react-bootstrap'
import { avatars, Icon, translate as _ } from '@morawadigital/skynet-framework'
import { getStyle, getStyleIndex } from '../../util'
import { Link } from 'react-router-dom'

class UserRow extends React.Component {

    render() {

        return (

            <Card className='user-row mb-2'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className={ this.props.user ? 'd-flex align-items-center' : '' } xs={ 12 } xl={ 6 }>

                            {

                                this.props.user ?

                                    <>

                                        <img src={ this.props.user.Image || avatars.male } alt='' className='profile-image rounded-circle me-3' />

                                        <span className='fs-5'>{ this.props.user.Lastname } { this.props.user.Firstname }</span>

                                    </>

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 6 } /></Placeholder>

                            }

                        </Col>

                        <Col className='fs-6' xs={ 8 } xl={ 4 }>

                            {

                                this.props.user ?

                                    this.props.user.Roles && this.props.user.Roles.map( ( e, i ) => <Badge className='me-1' key={ i } bg={ getStyle( getStyleIndex( e.Name ) ) }>{ e.Name }</Badge> )

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 3 } bg='primary' /> <Placeholder xs={ 3 } bg='info' /></Placeholder>

                            }

                        </Col>

                        <Col className='text-end' xl={ 2 }>

                            {

                                this.props.user ?

                                    <Link to={ '/user/' + this.props.user.Id } className='btn btn-secondary'><span className='d-sm-none'><Icon icon='edit' /></span><span className='d-none d-sm-inline'>{ _( 'Bearbeiten' ) }</span></Link>

                                :

                                    <Placeholder.Button variant='secondary' xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default UserRow