import React from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Link, withRouter } from 'react-router-dom'

class RegionRow extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            detailsOpen: false,

        }

    }

    toggleDetails() {

        this.setState( { detailsOpen: ! this.state.detailsOpen } )

    }

    render() {

        return (

            <Card className='mb-4'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className={ this.props.region ? 'd-flex align-items-center' : '' } xs={ 9 }>

                            {

                                this.props.region ?

                                    <div className='fs-5'>{ this.props.region.Name }</div>

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 6 } /></Placeholder>

                            }

                        </Col>

                        <Col className='text-end' xs={ 3 }>

                            {

                                this.props.region ?

                                    <Link to={ '/region/' + this.props.region.Id } className='btn btn-secondary'><span className='d-sm-none'><Icon icon='edit' /></span><span className='d-none d-sm-inline'>{ _( 'Bearbeiten' ) }</span></Link>

                                :

                                    <Placeholder.Button variant='secondary' xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default withRouter( RegionRow )