import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { translate as _ } from '@morawadigital/skynet-framework'

class InvoicePosition extends React.Component {

    changePosition( e ) {

        this.props.onChange( e.target.name, e.target.type === 'checkbox' ? e.target.checked : e.target.value, this.props.index )

    }

    renderInput( options ) {

        const value = this.props.position[ options.name ]

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.name + '-' + this.props.index }>

                <Form.Label column sm={ 3 }>{ options.label }</Form.Label>

                <Col sm={ 9 }>

                    <InputGroup>

                        { options.text && <InputGroup.Text>{ options.text }</InputGroup.Text> }

                        <Form.Control
                            name={ options.name }
                            onChange={ options.readOnly ? null : e => this.changePosition( e ) }
                            readOnly={ options.readOnly }
                            value={ value === null ? '' : value }
                        />

                    </InputGroup>

                </Col>

            </Form.Group>

        )

    }

    renderSwitch( options ) {

        return (

            <div className='ps-4 pt-1 flex-grow-1'>

                <Form.Check id={ options.name + '-' + this.props.index } type='switch'>

                    <Form.Check.Input checked={ this.props.position[ options.name ] } className='fs-6' name={ options.name } onChange={ e => this.changePosition( e ) } />

                    <Form.Check.Label>{ options.label }</Form.Check.Label>

                </Form.Check>

            </div>

        )

    }

    render() {

        return (

            <Row className={ this.props.className }>

                <Col xs='12' xl='6'>

                    { this.renderInput( { label: _( 'Name'    ), name: 'RefereeName', readOnly: true } ) }

                    { this.renderInput( { label: _( 'Wohnort' ), name: 'RefereeCity', readOnly: true } ) }

                    { this.renderInput( { label: _( 'IBAN'    ), name: 'RefereeIBAN', readOnly: true } ) }

                    { this.renderInput( { label: _( 'BIC'     ), name: 'RefereeBIC',  readOnly: true } ) }

                </Col>

                <Col xs='12' xl='6'>

                    <Row className='mb-3'>

                        <Col md={ { offset: 3, span: 9 } } className='d-flex flex-fill flex-wrap standalone-switch'>

                            { this.renderSwitch( { label: _( 'Ref 10er'     ), name: 'Ref10'      } ) }

                            { this.renderSwitch( { label: _( 'AFL'          ), name: 'AFL'        } ) }

                            { this.renderSwitch( { label: _( 'Liegewagen'   ), name: 'Liegewagen' } ) }

                            { this.renderSwitch( { label: _( 'Übernachtung' ), name: 'Overnight'  } ) }

                        </Col>

                    </Row>

                    { this.renderInput( { label: _( 'Fahrtkosten' ), name: 'RideCosts',  text: '€'                 } ) }

                    { this.renderInput( { label: _( 'Zeitaufwand' ), name: 'TimeEffort', text: '€'                 } ) }

                    { this.renderInput( { label: _( 'Summe'       ), name: 'total',      text: '€', readOnly: true } ) }

                </Col>

            </Row>

        )

    }

}

export default InvoicePosition