import React from 'react'
import { Button, DropdownButton, FloatingLabel, Form } from 'react-bootstrap'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { AVAILABLE_REFEREES_SORT_BY_OPTION_NAME, AVAILABLE_REFEREES_SORT_BY_OPTION_SEASON_COUNT, AVAILABLE_REFEREES_SORT_BY_OPTION_ZIP_CODE } from '../../util/constants'

class AvailableRefereesTools extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            dropdownOpen:  false,
            filterName:    props.filterName,
            filterZipCode: props.filterZipCode,
            hasChange:     false,
            sortBy:        props.sortBy,

        }

    }

    apply() {

        this.setState( { dropdownOpen: false, hasChange: false }, () => this.props.onChange( this.state.filterName, this.state.filterZipCode, this.state.sortBy ) )

    }

    change( e ) {

        this.setState( { [ e.target.name ]: e.target.value, hasChange: true } )

    }

    toggleDropdown() {

        this.setState( { dropdownOpen: ! this.state.dropdownOpen } )

    }

    render() {

        return (

            <div className='d-inline-flex'>

                <DropdownButton
                    title={ <Icon icon='filter' /> }
                    variant={ this.props.filterName || this.props.filterZipCode ? 'warning' : 'outline-secondary' }
                    size='sm'
                    align='end'
                    autoClose={ false }
                    show={ this.state.dropdownOpen }
                    onToggle={ () => this.toggleDropdown() }
                >

                    <div className='dropdown-form'>

                        <FloatingLabel label={ _( 'Sortierung' ) } className='mb-2'>

                            <Form.Select value={ this.state.sortBy } name='sortBy' onChange={ e => this.change( e ) }>

                                <option value={ AVAILABLE_REFEREES_SORT_BY_OPTION_NAME         }>{ _( 'Name'                       ) }</option>
                                <option value={ AVAILABLE_REFEREES_SORT_BY_OPTION_ZIP_CODE     }>{ _( 'PLZ'                        ) }</option>
                                <option value={ AVAILABLE_REFEREES_SORT_BY_OPTION_SEASON_COUNT }>{ _( 'Anzahl Spiele diese Saison' ) }</option>

                            </Form.Select>

                        </FloatingLabel>

                        <FloatingLabel label={ _( 'Name' ) } className='mb-2'>

                            <Form.Control value={ this.state.filterName } name='filterName' onChange={ e => this.change( e ) } />

                        </FloatingLabel>

                        <FloatingLabel label={ _( 'Postleitzahl (beginnend mit)' ) } className='mb-2'>

                            <Form.Control value={ this.state.filterZipCode } name='filterZipCode' onChange={ e => this.change( e ) } />

                        </FloatingLabel>

                        <div className='d-grid'>

                            <Button onClick={ () => this.apply() } disabled={ ! this.state.hasChange }>{ _( 'Übernehmen' ) }</Button>

                        </div>

                    </div>

                </DropdownButton>

            </div>

        )

    }

}

export default AvailableRefereesTools