import React from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getCoachResultStatusIcon, getRefereeFullName } from '../../util'
import { coachResultStatus } from '../../util/enum'
import GameRowInfoColumn from './GameRowInfoColumn'
import RefereeBadge from './RefereeBadge'

class EvaluationGameRow extends React.Component {

    render() {

        return (

            <Card className='mb-2'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 6 }>

                            <GameRowInfoColumn game={ this.props.game && this.props.game.Game } />

                        </Col>

                        <Col xs={ 12 } xl={ 6 }>

                            {

                                this.props.game ?

                                    <div className='d-flex align-items-center'>

                                        { this.props.game.CoachResults.map( ( e, i ) =>

                                            <Link to={ '/evaluierung/' + e.Id } key={ i }>

                                                <RefereeBadge
                                                    className={ 'me-1 my-2 with-icon coach-result-status-' + e.Status }
                                                    displayName={ <>{ getCoachResultStatusIcon( e.Status ) } { getRefereeFullName( e.Referee ) }</> }
                                                    label={ e.RefereeSystemJob && e.RefereeSystemJob.Shortname }
                                                    title={ coachResultStatus[ e.Status ] }
                                                />

                                            </Link>

                                        ) }

                                    </div>

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 8 } /></Placeholder>

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default EvaluationGameRow