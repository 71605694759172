import React from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import { Icon, avatars, parseJSON } from '@morawadigital/skynet-framework'
import Lightbox from './Lightbox'

class ContactRow extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            lightboxOpen: false,

        }

    }

    toggleLightbox() {

        this.setState( { lightboxOpen: ! this.state.lightboxOpen } )

    }

    render() {

        const allEmailAddressses = []
        const phoneNumbers       = this.props.contact ? parseJSON( this.props.contact.PhoneNumbers ) : null
        const emailAddresses     = this.props.contact ? parseJSON( this.props.contact.Mails        ) : null

        if ( this.props.contact ) {

            this.props.contact.AccountMail && allEmailAddressses.push( this.props.contact.AccountMail )
            emailAddresses                 && allEmailAddressses.push( ...emailAddresses )

        }

        return (

            <>

                <Card className='contact-row mb-2'>

                    <Card.Body>

                        <Row className='align-items-center'>

                            <Col className={ this.props.contact ? ' d-flex align-items-center' : '' } xs={ 12 } xl={ 3 }>

                                {

                                    this.props.contact ?

                                        <>

                                            <span className={ 'position-relative me-3' + ( this.props.contact.PressImage ? ' cursor-pointer' : '' ) } onClick={ this.props.contact.PressImage ? () => this.toggleLightbox() : null }>

                                                <img src={ this.props.contact.Image || avatars.male } alt='' className='profile-image rounded-circle border' />

                                                { this.props.contact.PressImage && <Icon icon='camera' className='image-icon text-primary' /> }

                                            </span>

                                            <span className='fs-5'>{ this.props.contact.Lastname } { this.props.contact.Firstname }</span>

                                        </>

                                    :

                                        <>

                                            <Placeholder animation='wave' className='me-3 placeholder-xl'>

                                                <Placeholder xs={ 1 } className='profile-image rounded-circle border d-inline-block' />

                                            </Placeholder>

                                            <Placeholder animation='wave' className='placeholder-lg'>

                                                <Placeholder xs={ 6 } />

                                            </Placeholder>

                                        </>

                                }

                            </Col>

                            <Col xs={ 12 } md={ 6 } xl={ 5 } className='py-2'>

                                {

                                    this.props.contact ?

                                        allEmailAddressses.length > 0 &&

                                        <>

                                            <Icon icon='envelope' className='me-2' />

                                            { allEmailAddressses.map( ( e, i ) => <React.Fragment key={ i }><a href={ 'mailto:' + e }>{ e }</a>{ i + 1 < allEmailAddressses.length && <> &bull; </> }</React.Fragment> ) }

                                        </>

                                    :

                                        <Placeholder animation='wave'><Placeholder xs={ 8 } bg='primary' /></Placeholder>

                                }

                            </Col>

                            <Col xs={ 12 } md={ 6 } xl={ 4 }>

                                {

                                    this.props.contact ?

                                        phoneNumbers && phoneNumbers.length > 0 &&

                                        <>

                                            <Icon icon='phone' className='me-2' />

                                            { phoneNumbers.map( ( e, i ) => <React.Fragment key={ i }><a href={ 'tel:' + e }>{ e }</a>{ i + 1 < phoneNumbers.length && <> &bull; </> }</React.Fragment> ) }

                                        </>

                                    :

                                        <Placeholder animation='wave'><Placeholder xs={ 8 } bg='primary' /></Placeholder>

                                }

                            </Col>

                        </Row>

                    </Card.Body>

                </Card>

                { this.props.contact && this.props.contact.PressImage &&

                    <Lightbox show={ this.state.lightboxOpen } onClose={ () => this.toggleLightbox() }>

                        <img src={ this.props.contact.PressImage } alt='' className='shadow' />

                    </Lightbox>

                }

            </>

        )

    }

}

export default ContactRow