import React from 'react'
import { Icon } from '@morawadigital/skynet-framework'
import { Button, Card, Col, Collapse, Row } from 'react-bootstrap'
import RefereeBusinessCard from './RefereeBusinessCard'
import GameRowInfoColumn from './GameRowInfoColumn'
import GameRowRefereesColumn from './GameRowRefereesColumn'

class PublicScheduleGameRow extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            infoOpen: false,

        }

    }

    getReferee( e ) {

        return e.RefereeId && ! e.FederationId ? {

            Id:               e.RefereeId,
            Firstname:        e.RefereeFirstName,
            Lastname:         e.RefereeLastName,
            RefereeSystemJob: e.RefereeSystemJob,
            assignment:       e,

        } : null

    }

    toggleInfo() {

        this.setState( { infoOpen: ! this.state.infoOpen } )

    }

    renderButton( onClick, variant, label, size ) {

        return <Button onClick={ onClick } disabled={ this.props.isProcessing } variant={ variant } size={ size || '' }>{ label }</Button>

    }

    render() {

        const contacts = []

        this.props.game && this.props.game.MapRefereeGames && this.props.game.MapRefereeGames.forEach( assignment => {

            assignment.RefereeId && contacts.push( assignment )

        } )

        return (

            <Card className='mb-2'>

                <Card.Body className='p-0'>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } lg={ 5 } className='py-1 ps-4 pe-0'>

                            <GameRowInfoColumn
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                noCheckbox={ true }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } lg={ 7 } className='px-4'>

                            <GameRowRefereesColumn
                                federations={ this.props.federations }
                                game={ this.props.game }
                                ignoreStatus={ true }
                                referees={ this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : [] }
                            >

                                { contacts.length > 0 && <Button variant={ ( this.state.infoOpen ? '' : 'outline-' ) + 'info' } size='sm' className='my-2 py-1' onClick={ () => this.toggleInfo() }><Icon icon='info' /></Button> }

                            </GameRowRefereesColumn>

                        </Col>

                    </Row>

                    <Collapse in={ this.state.infoOpen }>

                        <div>

                            <div className='d-flex justify-content-center flex-wrap my-3'>

                                { contacts.map( ( e, i ) => <RefereeBusinessCard key={ i } referee={ { Firstname: e.RefereeFirstName, Lastname: e.RefereeLastName, assignment: { ShortName: e.RefereeSystemJob ? e.RefereeSystemJob.ShortName : '' }, Image: e.Image, PressImage: e.PressImage } } hideContactInfo={ true } /> ) }

                            </div>

                        </div>

                    </Collapse>

                </Card.Body>

            </Card>

        )

    }

}

export default PublicScheduleGameRow