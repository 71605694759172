import { connect } from 'react-redux'
import { createDate, storeActions } from '@morawadigital/skynet-framework'
import Evaluation from '../../components/pages/Evaluation'
import { setDateRange, setDivisions } from '../../store/actions'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFrom ),
        dateRangeTo:   createDate( state.ui.dateRangeTo   ),
        divisions:     state.ui.divisions || [],
        loading:       state.app.loading,
        token:         state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange: ( from, to ) => dispatch( setDateRange( from, to                  ) ),
        onDivisionsChange: divisions    => dispatch( setDivisions( divisions                 ) ),
        onToggleLoading:   loading      => dispatch( storeActions.app.toggleLoading( loading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Evaluation )