import React from 'react'
import { ajax, getDataUrl, Icon, fillDate, translate as _ } from '@morawadigital/skynet-framework'
import { withRouter } from 'react-router-dom'
import { Button, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import TreeSelect from '../controls/TreeSelect'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { convertLeagues, getDateRangePickerLocale } from '../../util'
import EvaluationGameRow from '../elements/EvaluationGameRow'

class Evaluation extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            divisions:       null,
            games:           null,
            hasLoadingError: false,
            loadingGames:    false,

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.loadGames(), 200 )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Schedule/getScheduleableLeagues' ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { divisions: convertLeagues( e.Data ) }, () => this.loadGames() ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    loadGames() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        const data = {

            divIds: { __arr: true, values: this.props.divisions },
            from:   this.props.dateRangeFrom.toJSON(),
            to:     this.props.dateRangeTo.toJSON(),
            token:  this.props.token,

        }

        const success = games => {

            games.forEach( game => {

                game.HomeTeam = { Name: game.HomeTeam, ShortName: game.ShortHomeTeam }
                game.AwayTeam = { Name: game.AwayTeam, ShortName: game.ShortAwayTeam }

            } )

            this.setState( { games } )

        }

        this.setState( { hasLoadingError: false, loadingGames: true }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Coaching/getCoachResults' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { loadingGames: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='list' className='subheader-icon' /> { _( 'Evaluierung' ) }

                    </h1>

                </div>

                <Form>

                    <Row className='align-items-center mb-3 px-3'>

                        <Col xs={ 12 } sm={ 6 } xl={ { span: 4, offset: 2 } } className='mb-1'>

                            <Form.Group as={ Row } controlId='league'>

                                <Form.Label column xs={ 4 } className='text-end'>{ _( 'Liga' ) }</Form.Label>

                                <Col xs={ 8 }>

                                    <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                                </Col>

                            </Form.Group>

                        </Col>

                        <Col xs={ 12 } sm={ 6 } xl={ 4 } className='mb-1'>

                            <Form.Group as={ Row } controlId='date-range'>

                                <Form.Label column xs={ 4 } className='text-end'>{ _( 'Zeitraum' ) }</Form.Label>

                                <Col xs={ 8 }>

                                    <DateRangePicker initialSettings={ {

                                        endDate:   this.props.dateRangeTo,
                                        locale:    getDateRangePickerLocale(),
                                        startDate: this.props.dateRangeFrom,

                                    } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                        <Form.Control />

                                    </DateRangePicker>

                                </Col>

                            </Form.Group>

                        </Col>

                    </Row>

                </Form>

                {

                    this.state.loadingGames ?

                        Array.from( { length: 3 } ).map( ( e, i ) => <EvaluationGameRow key={ i } /> )

                    : this.state.games && ! this.state.games.length ?

                        <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                    : this.state.games ?

                        <div>{ this.state.games.map( ( game, i ) => <EvaluationGameRow game={ game } key={ i } /> ) }</div>

                    : ''

                }

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Evaluation )