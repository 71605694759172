import { ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Card, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { sortByName } from '../../util'

class Team extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            federations:            null,
            hasLoadingError:        false,
            hasSavingError:         false,
            isSaving:               false,
            showSaveSuccessMessage: false,
            team:                   null,

        }

    }

    componentDidMount() {

        this.load()

    }

    changeTeam( e ) {

        this.setState( { team: { ...this.state.team, [ e.target.name ]: e.target.type === 'checkbox' ? e.target.checked : Number( e.target.value ) } } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    handleFormSubmit( e ) {

        e.preventDefault()

        this.saveTeam()

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const setTeam = ( e, resolve ) => {

                const team = e[ 0 ]

                team.federationId = team.Federation ? team.Federation.Id : 0

                this.setState( { team } )

                resolve()

            }

            const itemsToLoad = [

                this.loadItems( { url: 'api/Federation/Get', name: 'federations',                           fn:      sortByName } ),
                this.loadItems( { url: 'api/Team/Get',       data: { id: this.props.match.params.teamId }, success: setTeam    } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token, ...( options.data || {} ) }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve) : this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    saveTeam() {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const data = {

                id:             this.state.team.Id,
                name:           this.state.team.Name,
                autoAssignment: this.state.team.AutomaticFederationAssignment,
                token:          this.props.token,

            }

            if ( Number( this.state.team.federationId ) ) {

                data.fedId = this.state.team.federationId

            }

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Team/AddOrEdit' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { showSaveSuccessMessage: true }, () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    render() {

        const formDisabled = this.props.loading || this.state.hasLoadingError || this.state.isSaving

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='shield' className='subheader-icon' /> { _( 'Team' ) } { this.state.team && <span className='fw-300'>{ this.state.team.Name }</span> }

                    </h1>

                    <Link to={ this.state.team && this.state.team.LeagueId ? '/liga/' + this.state.team.LeagueId : '/ligaverwaltung' } className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Card>

                    <Form onSubmit={ e => this.handleFormSubmit( e ) }>

                        <fieldset disabled={ formDisabled }>

                            <Card.Body>

                                <Form.Group as={ Row } className='mb-3 align-items-center' controlId='automaticFederationAssignment'>

                                    <Form.Label column sm={ 6 }>{ _( 'Automatisch Verband zuweisen' ) }</Form.Label>

                                    <Col sm={ 6 } className='fs-2 standalone-switch'>

                                        <Form.Check type='switch' checked={ this.state.team ? this.state.team.AutomaticFederationAssignment : false } name='AutomaticFederationAssignment' onChange={ e => this.changeTeam( e ) } />

                                    </Col>

                                </Form.Group>

                                <Form.Group as={ Row } className='mb-3' controlId='federationId'>

                                    <Form.Label column sm={ 6 }>{ _( 'LEV' ) }</Form.Label>

                                    <Col sm={ 6 }>

                                        <Form.Select value={ this.state.team ? this.state.team.federationId : 0 } name='federationId' onChange={ e => this.changeTeam( e ) }>

                                            <option />

                                            { this.state.federations ? this.state.federations.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) : <option></option> }

                                        </Form.Select>

                                    </Col>

                                </Form.Group>

                            </Card.Body>

                            <Card.Footer>

                                <Button type='submit'>{ _( 'Speichern' ) }</Button>

                            </Card.Footer>

                        </fieldset>

                    </Form>

                </Card>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Team )