import { connect } from 'react-redux'
import App from '../components/App'

const mapStateToProps = state => {

    return {

        hasScheduleLocalChanges: state.localChanges && state.localChanges.schedule && Object.keys( state.localChanges.schedule ).length,
        user:                    state.auth.user,

    }

}

export default connect( mapStateToProps )( App )