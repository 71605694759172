import { ExternalForm, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Link } from 'react-router-dom'
import FeedbackForm from '../../containers/elements/FeedbackForm'

class FeedbackStandalone extends React.Component {

    render() {

        return (

            <ExternalForm headerRight={ <Link to={ '/' } className='btn-link text-white'>{ _( 'Zur Startseite' ) }</Link> } headerText={ _( 'Feedback' ) } logoImageUrl={ this.props.logoImageUrl } logoText={ this.props.logoText }>

                <FeedbackForm />

            </ExternalForm>

        )

    }

}

export default FeedbackStandalone