import { SET_AVAILABLE_REFEREES_TOOLS_VALUES, SET_DATE_RANGE, SET_DIVISIONS, SET_FILTER_LEAGUE_TYPE, SET_INVOICE_MANAGEMENT_FILTER_QUERY, SET_LEAGUES_FILTER_QUERY, SET_NOTIFICATION_GAME_FILTER_QUERY, SET_REGIONS, SET_RELEASE_GAME_FILTER_QUERY, SET_SCHEDULE_GAME_FILTER_QUERY, SET_SCHEDULE_LOCAL_CHANGES, SET_USERS_FILTER_DATE, SET_USERS_FILTER_QUERY, TOGGLE_ALTERNATIVE_SCHEDULE_LAYOUT_IS_ENABLED, TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED, TOGGLE_DIVISION_DISPLAY_IS_ENABLED, TOGGLE_SCHEDULE_HEADER_IS_HIDDEN } from './constants'

export const setAvailableRefereesToolsValues = ( filterName, filterZipCode, sortBy ) => {

    return {

        type:    SET_AVAILABLE_REFEREES_TOOLS_VALUES,
        payload: { filterName, filterZipCode, sortBy },

    }

}

export const setDateRange = ( from, to, keyFrom = 'dateRangeFrom', keyTo = 'dateRangeTo' ) => {

    return {

        type:    SET_DATE_RANGE,
        payload: { from, to, keyFrom, keyTo },

    }

}

export const setDivisions = ( divisions, key = 'divisions' ) => {

    return {

        type:    SET_DIVISIONS,
        payload: { divisions, key },

    }

}

export const setFilterLeagueType = filterLeagueType => {

    return {

        type:    SET_FILTER_LEAGUE_TYPE,
        payload: { filterLeagueType },

    }

}

export const setInvoiceManagementFilterQuery = filterQuery => {

    return {

        type:    SET_INVOICE_MANAGEMENT_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const setLeaguesFilterQuery = filterQuery => {

    return {

        type:    SET_LEAGUES_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const setNotificationGameFilterQuery = filterQuery => {

    return {

        type:    SET_NOTIFICATION_GAME_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const setRegions = regions => {

    return {

        type:    SET_REGIONS,
        payload: { regions },

    }

}

export const setReleaseGameFilterQuery = filterQuery => {

    return {

        type:    SET_RELEASE_GAME_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const setScheduleGameFilterQuery = filterQuery => {

    return {

        type:    SET_SCHEDULE_GAME_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const setScheduleLocalChanges = localChanges => {

    return {

        type:    SET_SCHEDULE_LOCAL_CHANGES,
        payload: { localChanges },

    }

}

export const setUsersFilterDate = filterDate => {

    return {

        type:    SET_USERS_FILTER_DATE,
        payload: { filterDate },

    }

}

export const setUsersFilterQuery = filterQuery => {

    return {

        type:    SET_USERS_FILTER_QUERY,
        payload: { filterQuery },

    }

}

export const toggleAlternativeScheduleLayoutIsEnabled = isEnabled => {

    return {

        type:    TOGGLE_ALTERNATIVE_SCHEDULE_LAYOUT_IS_ENABLED,
        payload: { isEnabled },

    }

}

export const toggleDisplayNotShowByDefaultRefereesIsEnabled = isEnabled => {

    return {

        type:    TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED,
        payload: { isEnabled },

    }

}

export const toggleDivisionDisplayIsEnabled = isEnabled => {

    return {

        type:    TOGGLE_DIVISION_DISPLAY_IS_ENABLED,
        payload: { isEnabled },

    }

}

export const toggleScheduleHeaderIsHidden = isHidden => {

    return {

        type:    TOGGLE_SCHEDULE_HEADER_IS_HIDDEN,
        payload: { isHidden },

    }

}