import { connect } from 'react-redux'
import { storeActions } from '@morawadigital/skynet-framework'
import Dashboard from '../../components/pages/Dashboard'

const mapStateToProps = state => {

    return {

        loading: state.app.loading,
        token:   state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleLoading: loading => dispatch( storeActions.app.toggleLoading( loading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Dashboard )