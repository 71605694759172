import { connect } from 'react-redux'
import { createDate, storeActions } from '@morawadigital/skynet-framework'
import Release from '../../components/pages/Release'
import { setDateRange, setDivisions, setReleaseGameFilterQuery } from '../../store/actions'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFrom ),
        dateRangeTo:   createDate( state.ui.dateRangeTo   ),
        divisions:     state.ui.divisions || [],
        filterQuery:   state.ui.releaseGameFilterQuery || '',
        loading:       state.app.loading,
        token:         state.auth.token,
        user:          state.auth.user,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange:   ( from, to ) => dispatch( setDateRange( from, to                  ) ),
        onDivisionsChange:   divisions    => dispatch( setDivisions( divisions                 ) ),
        onFilterQueryChange: filterQuery  => dispatch( setReleaseGameFilterQuery( filterQuery  ) ),
        onToggleLoading:     loading      => dispatch( storeActions.app.toggleLoading( loading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Release )