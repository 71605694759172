import { connect } from 'react-redux'
import { storeActions } from '@morawadigital/skynet-framework'
import MySchedule from '../../components/pages/MySchedule'

const mapStateToProps = state => {

    return {

        loading: state.app.loading,
        token:   state.auth.token,
        user:    state.auth.user,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleLoading: loading => dispatch( storeActions.app.toggleLoading( loading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( MySchedule )