class DataTransferObject {

    data

    constructor( data ) {

        this.data = data || {}

    }

    get() {

        return this.data

    }

}

export default DataTransferObject