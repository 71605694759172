import { connect } from 'react-redux'
import { storeActions } from '@morawadigital/skynet-framework'
import LeagueManagement from '../../components/pages/LeagueManagement'
import { setFilterLeagueType, setLeaguesFilterQuery } from '../../store/actions'

const mapStateToProps = state => {

    return {

        filterLeagueType: state.ui.filterLeagueType   || '',
        filterQuery:      state.ui.leaguesFilterQuery || '',
        loading:          state.app.loading,
        token:            state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onSetFilterLeagueType: filterLeagueType => dispatch( setFilterLeagueType(            filterLeagueType ) ),
        onSetFilterQuery:      filterQuery      => dispatch( setLeaguesFilterQuery(          filterQuery      ) ),
        onToggleLoading:       loading          => dispatch( storeActions.app.toggleLoading( loading          ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( LeagueManagement )