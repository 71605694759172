import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import {ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import { isGuid } from '../../util'
import CtCInput from '../controls/CtCInput'

class CalendarModal extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            calendarToken:                 false,
            hasCreatingCalendarTokenError: false,
            isCreatingCalendarToken:       false,

        }

    }

    createCalendarToken() {

        this.setState( { hasCreatingCalendarTokenError: false, isCreatingCalendarToken: true }, () => setTimeout( () => {

            ajax( getDataUrl( 'api/Referee/CreateCalendarToken' ) + '?token=' + this.props.token )
                .then( e => e.StatusId > 0 ? this.setState( { calendarToken: e.Data } ) : this.error() )
                .catch( () => this.error() )
                .finally( () => this.setState( { isCreatingCalendarToken: false } ) )

            }, 400 ) )

    }

    error() {

        this.setState( { hasCreatingCalendarTokenError: true } )

    }

    render() {

        const calendarToken = isGuid( this.state.calendarToken ) ? this.state.calendarToken : ( isGuid( this.props.user.CalendarToken ) ? this.props.user.CalendarToken : null )

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } centered>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Kalender' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    {

                        calendarToken ?

                            <>

                                <p className='lead'>{ _( 'Verwenden Sie folgende URL, um in Ihrer Kalender App einen externen Kalender hinzuzufügen.' ) }</p>

                                <div className='my-5'>

                                    <CtCInput value={ getDataUrl( 'api/Referee/GetCalendar' ) + '?calendartoken=' + calendarToken } inputGroupProps={ { size: 'lg' } } />

                                </div>

                                <Alert variant='danger'><Icon icon='exclamation-triangle' /> { _( 'Achtung! Halten Sie diese URL geheim, da diese verwendet werden kann, um Ihre Einteilungen anzusehen.' ) }</Alert>

                                <div className='my-5 text-center'>

                                    <Button onClick={ () => this.createCalendarToken() } disabled={ this.state.isCreatingCalendarToken }>{ _( 'Neue URL generieren' ) }</Button>

                                </div>

                            </>

                        :

                            <>

                                <p className='lead'>{ _( 'Klicken Sie auf "URL generieren" und verwenden Sie die erzeugte URL, um in Ihrer Kalender App einen externen Kalender hinzuzufügen.' ) }</p>

                                <div className='my-5 text-center'>

                                    <Button onClick={ () => this.createCalendarToken() } disabled={ this.state.isCreatingCalendarToken }>{ _( 'URL generieren' ) }</Button>

                                </div>

                                { this.state.hasCreatingCalendarTokenError && <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                            </>

                    }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default CalendarModal