import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { getGameTitle } from '../../util'

class GameNotesModal extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            errorSaving: false,
            isSaving:    false,
            notes:       '',
            show:        false,

        }

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            notes: this.props.game.Note || '',
            show:  this.props.show,

        } )

    }

    change( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    closeModal() {

        this.setState( { isSaving: false }, () => this.props.onHide() )

        return true

    }

    save() {

        const data = {

            gameId: this.props.game.Id,
            note:   this.state.notes,
            token:  this.props.token,

        }

        this.setState( { errorSaving: false, isSaving: true }, () =>

            ajax( getDataUrl( 'api/Schedule/saveGameNote' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.closeModal() && this.props.onChange( this.state.notes ) : this.setState( { errorSaving: true } ) )
                .catch( () => this.setState( { errorSaving: true } ) )
                .finally( () => this.setState( { isSaving: false } ) )

        )

    }

    render() {

        return (

            <Modal show={ this.state.show } onHide={ () => this.closeModal() } centered backdrop='static'>

                <Modal.Header closeButton>

                    <Modal.Title>{ getGameTitle( this.props.game ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <fieldset disabled={ this.state.isSaving }>

                        <FloatingLabel label={ _( 'Notizen' ) } className='mb-3'>

                            <Form.Control
                                as='textarea'
                                autoFocus
                                name='notes'
                                onChange={ e => this.change( e ) }
                                readOnly={ ! this.props.canEdit }
                                style={ { height: '150px' } }
                                value={ this.state.notes }
                            />

                        </FloatingLabel>

                    </fieldset>

                    { this.state.errorSaving && <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Notizen konnten nicht gespeichert werden.' ) }</Alert> }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.closeModal() } disabled={ this.state.isSaving } variant='secondary'>{ _( 'Schließen' ) }</Button>

                    { this.props.canEdit && <Button onClick={ () => this.save() } disabled={ this.state.isSaving }>{ _( 'Speichern' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default GameNotesModal