import React from 'react'
import { ajax, getDataUrl, Icon, createDate, createTimeWithOffset, deepCopy, formatDate, formatTime, translate as _ } from '@morawadigital/skynet-framework'
import { Link, withRouter } from 'react-router-dom'
import { getCustomerKey } from '../../util/customer'
import { Badge, Button, Card, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import InvoicePosition from '../elements/InvoicePosition'
import { friendlyFormatIBAN } from 'ibantools'
import { getNumber } from '../../util'

class Invoice extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError:           false,
            hasExportingError:         false,
            hasReleasingError:         false,
            hasSavingError:            false,
            hasUnsavedChanges:         false,
            isReleasing:               false,
            isSaving:                  false,
            invoice:                   null,
            showReleaseSuccessMessage: false,
            showSaveSuccessMessage:    false,

        }

    }

    componentDidMount() {

        this.load()

    }

    calculateTotal( position ) {

        let total = getNumber( position.RideCosts ) + getNumber( position.TimeEffort )

        if ( position.AFL        ) { total += position.AFLValue        }
        if ( position.Liegewagen ) { total += position.LiegewagenValue }
        if ( position.Overnight  ) { total += position.OvernightValue  }
        if ( position.Ref10      ) { total += position.Ref10Value      }

        return ( '' + total ).replace( '.', ',' )

    }

    changePosition( name, value, index ) {

        const positions = deepCopy( this.state.invoice.positions )

        positions[ index ][ name ] = value
        positions[ index ].total   = this.calculateTotal( positions[ index ] )

        this.setState( { invoice: { ...this.state.invoice, positions }, hasUnsavedChanges: true } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissReleaseSuccessMessage() {

        this.setState( { showReleaseSuccessMessage: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissExportingError() {

        this.setState( { hasExportingError: false } )

    }

    dismissReleasingError() {

        this.setState( { hasReleasingError: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorReleasing() {

        this.setState( { hasReleasingError: true } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    exportInvoice() {

        this.setState( { hasExportingError: false }, () => {

            const init = {

                method: 'POST',
                body:   new FormData(),

            }

            init.body.append( 'customer', getCustomerKey()      )
            init.body.append( 'id',       this.state.invoice.Id )
            init.body.append( 'token',    this.props.token      )

            fetch( getDataUrl( 'api/Invoice/DownloadInvoice' ), init )
                .then( e => e.blob() )
                .then( e => window.location.assign( window.URL.createObjectURL( e ) ) )
                .catch( () => this.setState( { hasExportingError: true } ) )

        } )

    }

    handleFormSubmit( e ) {

        e.preventDefault()

        ! this.state.invoice.Released && this.saveInvoice()

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const success = e => {

                this.setState( { invoice: {

                    ...e,

                    days:      1,
                    from:      formatDate( e.GameDate ) + ' ' + formatTime( e.GameDate ),
                    to:        formatTime( createTimeWithOffset( ( 1000 * 60 * 60 * 3 ), createDate( e.GameDate ) ) ),
                    positions: e.Positions.map( position => {

                        return { ...position, RefereeIBAN: position.RefereeIBAN && friendlyFormatIBAN( position.RefereeIBAN ), total: this.calculateTotal( position ) }

                    } )

                } } )

            }

            ajax( getDataUrl( 'api/Invoice/Get' ), { id: this.props.match.params.invoiceId, customer: getCustomerKey(), token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? success( e.Data[ 0 ] ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    releaseInvoice() {

        this.setState( { hasReleasingError: false, isReleasing: true, showReleaseSuccessMessage: false }, () => {

            const data = {

                customer: getCustomerKey(),
                id:       this.state.invoice.Id,
                release:  true,
                token:    this.props.token,

            }

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Invoice/ReleaseInvoices' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showReleaseSuccessMessage: true, invoice: { ...this.state.invoice, Released: true } }, () => setTimeout( () => this.state.showReleaseSuccessMessage && this.setState( { showReleaseSuccessMessage: false } ), 3000 ) ) : this.errorReleasing() )
                .catch( () => this.errorReleasing() )
                .finally( () => this.setState( { isReleasing: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    saveInvoice() {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const positions = this.state.invoice.positions.map( position => {

                return {

                    ...position,

                    RideCosts:  getNumber( position.RideCosts  ),
                    TimeEffort: getNumber( position.TimeEffort ),
                    Total:      getNumber( position.total      ),

                }

            } )

            const data = {

                customer:     getCustomerKey(),
                id:           this.state.invoice.Id,
                jsonInvLines: JSON.stringify( positions ),
                token:        this.props.token,

            }

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Invoice/AddOrEdit' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showSaveSuccessMessage: true, hasUnsavedChanges: false }, () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    renderInput( options ) {

        const value = this.state.invoice && this.state.invoice[ options.name ]

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.name }>

                <Form.Label column sm={ 3 }>{ options.label }</Form.Label>

                <Col sm={ 9 }>

                    <Form.Control value={ value === null ? '' : value } readOnly />

                </Col>

            </Form.Group>

        )

    }

    render() {

        const formDisabled = this.props.loading || this.state.hasLoadingError || this.state.isSaving || this.state.isReleasing || ( this.state.invoice && this.state.invoice.Released )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title text-wrap'>

                        <Icon icon='file-invoice' className='subheader-icon' /> { _( 'Verrechnung' ) } { this.state.invoice && <><span className='fw-300'>{ this.state.invoice.Name }</span> { this.state.invoice.Released && <Badge bg='success' className='fs-6 align-top'>{ _( 'Freigegeben' ) }</Badge> }</>}

                    </h1>

                    <Link to='/verrechnung' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Form onSubmit={ e => this.handleFormSubmit( e ) }>

                    <fieldset disabled={ formDisabled }>

                        <Card className='mb-2'>

                            <Card.Header>

                                <Card.Title>{ _( 'Spiel' ) }</Card.Title>

                            </Card.Header>

                            <Card.Body>

                                <Row>

                                    <Col xs='12' xl='6'>

                                        { this.renderInput( { label: _( 'Betreff'             ), name: 'Name'      } ) }

                                        { this.renderInput( { label: _( 'Zeitraum von'        ), name: 'from'      } ) }

                                        { this.renderInput( { label: _( 'Bis'                 ), name: 'to'        } ) }

                                    </Col>

                                    <Col xs='12' xl='6'>

                                        { this.renderInput( { label: _( 'Ort'                 ), name: 'GameCity'  } ) }

                                        { this.renderInput( { label: _( 'Tage'                ), name: 'days'      } ) }

                                        { this.renderInput( { label: _( 'Anzahl der Personen' ), name: 'PersonCnt' } ) }

                                    </Col>

                                </Row>

                            </Card.Body>

                        </Card>

                        <Card className='mb-2'>

                            <Card.Header>

                                <Card.Title>{ _( 'Personen' ) }</Card.Title>

                            </Card.Header>

                            <Card.Body>

                                { this.state.invoice && this.state.invoice.positions.map( ( e, i ) =>

                                    <InvoicePosition
                                        className={ i + 1 < this.state.invoice.positions.length ? 'border-bottom mb-3' : '' }
                                        index={ i }
                                        key={ i }
                                        onChange={ ( e, f, g ) => this.changePosition( e, f, g ) }
                                        position={ e }
                                    />

                                ) }

                            </Card.Body>

                        </Card>

                    </fieldset>

                    { this.state.invoice &&

                        <div className='mt-3 mb-2 d-flex'>

                            {

                                this.state.invoice.Released ?

                                    <Button variant='secondary' size='lg' onClick={ () => this.exportInvoice() }>{ _( 'Exportieren' ) }</Button>

                                :

                                    <>

                                        <Button size='lg' disabled={ formDisabled || ! this.state.hasUnsavedChanges } type='submit'>{ _( 'Speichern' ) }</Button>

                                        <Button size='lg' disabled={ formDisabled || this.state.hasUnsavedChanges } variant='warning' onClick={ () => this.releaseInvoice() } className='ms-auto'>{ _( 'Freigeben' ) }</Button>

                                    </>

                            }

                        </div>

                    }

                </Form>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissReleasingError() } show={ this.state.hasReleasingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Verrechnung konnte nicht freigegeben werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissReleaseSuccessMessage() } show={ this.state.showReleaseSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Verrechnung wurde freigegeben.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissExportingError() } show={ this.state.hasExportingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht exportiert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Invoice )