import React from 'react'
import { Badge, Card, Col, Placeholder, Row } from 'react-bootstrap'
import { Icon, formatDate, formatTime, translate as _ } from '@morawadigital/skynet-framework'
import { Link, withRouter } from 'react-router-dom'

class InvoiceRow extends React.Component {

    render() {

        return (

            <Card className='mb-4'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className={ this.props.invoice ? 'd-flex align-items-center' : '' } xs='12' sm='9' md='10'>

                            {

                                this.props.invoice ?

                                    <>

                                        <Badge className='me-3 fs-6' bg='dark'>

                                            <div>{ formatDate( this.props.invoice.GameDate, { year: 'none', weekday: 'short' } ) }</div>
                                            <div>{ formatTime( this.props.invoice.GameDate ) }</div>

                                        </Badge>

                                        <div className='fs-5'>

                                            { this.props.invoice.Name }

                                            { this.props.invoice.Released && <Badge bg='success' className='ms-1 fs-6'>{ _( 'Freigegeben' ) }</Badge> }

                                        </div>

                                    </>

                                :

                                    <Placeholder animation='wave'><Placeholder xs='6' /></Placeholder>

                            }

                        </Col>

                        <Col className='text-end' xs='12' sm='3' md='2'>

                            {

                                this.props.invoice ?

                                    <Link to={ '/verrechnung/' + this.props.invoice.Id } className='btn btn-secondary'><span className='d-sm-none'><Icon icon='edit' /></span><span className='d-none d-sm-inline'>{ _( 'Bearbeiten' ) }</span></Link>

                                :

                                    <Placeholder.Button variant='secondary' xs='3' />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default withRouter( InvoiceRow )