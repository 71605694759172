
import { Icon, createDate, createTimeWithOffset, fillDate, formatDate, formatTime, stripDate, translate as _, sortByKey } from '@morawadigital/skynet-framework'
import { EMPTY_GUID } from './constants'
import { getCustomer } from './customer'

const styles                 = [ 'primary', 'secondary', 'success', 'danger', 'warning', 'info' ]
const feedbackStatusIcons    = [ 'clock', 'envelope', 'envelope-circle-check', 'thumbs-up', 'thumbs-down', 'exclamation-circle' ]
const coachResultStatusIcons = [ 'circle', 'hourglass', 'check' ]

const editorConfig = {

    language:       'de',
    menubar:        false,
    plugins:        'autoresize,lists',
    statusbar:      false,
    toolbar:        [ 'formatselect | forecolor bold italic underline | alignleft aligncenter alignright | outdent indent | bullist numlist' ],
    toolbar_sticky: true,

}

export const capitalizeFirstLetter = e => e.charAt( 0 ).toUpperCase() + e.slice( 1 )

export const convertEnumToOptions = e => e.map( ( e, i ) => { return { value: i, label: e } } )

export const convertLeagues = leagues => {

    const divisions = []

    leagues.forEach( e => {

        if ( e.Inactive ) {

            return

        }

        const league = {

            label:    e.Name,
            value:    e.Id,
            children: [],

        }

        e.Divisions.forEach( e => league.children.push( { label: e.Name, value: e.Id } ) )

        divisions.push( league )

    } )

    return divisions

}

export const convertOptions = e => e.map( e => e.value )

export const convertToOptions = e => e.map( e => { return { value: e, label: e } } )

export const createOption = e => {

    return {

        value: e,
        label: e,

    }

}

export const getAbbr = ( e, f, g = 3 ) => ( e && ( f in e ) && e[ f ] ) ? e[ f ].substring( 0, g ) : ''

export const getCoachResultStatusIcon = e => <Icon icon={ coachResultStatusIcons[ e ] } fw />

export const getDateRangePickerLocale = () => { return {

    applyLabel:        _( 'Übernehmen' ),
    cancelLabel:       _( 'Abbrechen' ),
    customRangeLabel:  _( 'Custom' ),
    daysOfWeek:        [ _( 'So' ), _( 'Mo' ), _( 'Di' ), _( 'Mi' ), _( 'Do' ), _( 'Fr' ), _( 'Sa' ) ],
    firstDay:          1,
    format:            'DD.MM.YY',
    fromLabel:         _( 'Von' ),
    monthNames:        [ _( 'Januar' ), _( 'Februar' ), _( 'März' ), _( 'April' ), _( 'Mai' ), _( 'Juni' ), _( 'Juli' ), _( 'August' ), _( 'September' ), _( 'Oktober' ), _( 'November' ), _( 'Dezember' ) ],
    separator:         ' - ',
    toLabel:           _( 'Bis' ),
    weekLabel:         'W',

} }

export const getDefaultDateRange = past => {

    const offset = 1000 * 60 * 60 * 24 * 14

    if ( past ) {

        return {

            from: stripDate( createTimeWithOffset( offset * -1 ) ),
            to:   fillDate( createDate() ),

        }

    }

    return {

        from: stripDate( createDate() ),
        to:   fillDate( createTimeWithOffset( offset ) ),

    }

}

export const getEditorConfig = () => editorConfig

export const getFeedbackStatusIcon = e => <Icon icon={ feedbackStatusIcons[ e ] } fw />

export const getGameTitle = e => {

    return (

        <>

            { formatDate( e.ScheduleDate, { year: 'none', weekday: 'short' } ) }

            { ' ' }

            { formatTime( e.ScheduleDate ) }

            { ' | ' }

            <span title={ e.HomeTeam ? e.HomeTeam.Name : '' }>{ e.HomeTeam ? e.HomeTeam.ShortName : '???' }</span>
            { ' - ' }
            <span title={ e.AwayTeam ? e.AwayTeam.Name : '' }>{ e.AwayTeam ? e.AwayTeam.ShortName : '???' }</span>

        </>

    )

}

export const getNumber = e => {

    e = Number( ( '' + e ).replace( ',', '.' ) )

    if ( isNaN( e ) ) {

        e = 0

    }

    return e

}

export const getRefereeFullName = referee => {

    const fullName = []

    if ( referee ) {

        referee.Firstname && fullName.push( referee.Firstname )
        referee.Lastname  && fullName.push( referee.Lastname  )

    }

    return fullName.join( ' ' )

}

export const getRefereeShortName = referee => referee ? ( getCustomer().useAlternativeRefereeShortname ? getAbbr( referee, 'Lastname', 6 ) : getAbbr( referee, 'Lastname' ) + getAbbr( referee, 'Firstname' ) ) : ''

export const getShortName = e => typeof e === 'string' ? e.split( ' ' ).map( f => f.substring( 0, 3 ) ).join( '' ) : ''

export const getStyle = e => {

    return styles[ e < 0 || e > styles.length - 1 ? 0 : e ]

}

export const getStyleIndex = e => {

	let sum = 0

    e.split( '' ).forEach( e => { sum += e.charCodeAt( 0 ) } )

    return sum % styles.length

}

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isGuid = e => e && e !== EMPTY_GUID

export const requiredButNotAssigned = e => e.RefereeSystemJob && e.RefereeSystemJob.Required && ! e.FederationId && ! e.RefereeId

export const sortByLabel = e => sortByKey( e, 'label' )

export const sortByName = e => sortByKey( e, 'Name' )

export const sortByLastnameAndFirstname = e => e.sort( ( a, b ) => a.Lastname === b.Lastname ? ( a.Firstname < b.Firstname ? -1 : a.Firstname > b.Firstname ) : a.Lastname < b.Lastname ? -1 : a.Lastname > b.Lastname ? 1 : 0 )