import { connect } from 'react-redux'
import { createDate, storeActions } from '@morawadigital/skynet-framework'
import InvoiceManagement from '../../components/pages/InvoiceManagement'
import { setDateRange, setInvoiceManagementFilterQuery } from '../../store/actions'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFromInvoiceManagement ),
        dateRangeTo:   createDate( state.ui.dateRangeToFromInvoiceManagement   ),
        filterQuery:   state.ui.invoiceManagementFilterQuery || '',
        loading:       state.app.loading,
        token:         state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange:   ( from, to ) => dispatch( setDateRange( from, to, 'dateRangeFromInvoiceManagement', 'dateRangeToFromInvoiceManagement' ) ),
        onFilterQueryChange: filterQuery  => dispatch( setInvoiceManagementFilterQuery( filterQuery                                                 ) ),
        onToggleLoading:     loading      => dispatch( storeActions.app.toggleLoading( loading                                                      ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( InvoiceManagement )