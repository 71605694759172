import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Card, Col, Placeholder, Row } from 'react-bootstrap'
import GameRowInfoColumn from './GameRowInfoColumn'

class GameRow extends React.Component {

    render() {

        return (

            <Card className={ 'mb-2 game-row' + ( this.props.game && this.props.game.isDeleted ? ' deleted' : '' ) }>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } sm={ 10 }>

                            <GameRowInfoColumn
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } sm={ 2 } className='text-end'>

                            {

                                this.props.game ?

                                    <Button onClick={ () => this.props.onDeleteGame( this.props.game.Id ) } disabled={ this.props.deleting || this.props.game.isDeleted }>{ this.props.deleting ? <Icon spin icon='spinner' /> : this.props.game.isDeleted ? <Icon icon='thumbs-up' /> : _( 'Löschen' ) }</Button>

                                :

                                    <Placeholder.Button xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default GameRow