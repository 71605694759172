import React from 'react'
import { Button, Col, Row, Toast, ToastContainer } from 'react-bootstrap'
import { ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import UrgentChangeGameRow from '../../containers/elements/UrgentChangeGameRow'

class UrgentChanges extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError: false,
            loading:         false,
            urgentChanges:   null,

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    load() {

        this.setState( { hasLoadingError: false, loading: true }, () => setTimeout( () => {

            ajax( getDataUrl( 'api/Schedule/getUrgentChanges' ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { urgentChanges: e.Data } ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { loading: false } ) )

        }, 400 ) )

    }

    render() {

        return (

            <>

                <Row className='my-5 pb-5 border-bottom'>

                    <Col>

                        <h4>{ _( 'Dringliche Änderungen' ) }</h4>

                        {

                            this.state.loading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <UrgentChangeGameRow key={ i } /> )

                            : this.state.urgentChanges && ! this.state.urgentChanges.length ?

                                <div className='my-3 text-center'>{ _( 'Keine dringlichen Änderungen vorhanden.' ) }</div>

                            : this.state.urgentChanges ?

                                <div>

                                    { this.state.urgentChanges.map( ( game, i ) =>

                                        <UrgentChangeGameRow
                                            federations={ this.state.federations }
                                            game={ game }
                                            key={ i }
                                        />

                                    ) }

                                </div>

                            : ''

                        }

                    </Col>

                </Row>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default UrgentChanges