import { ajax, getDataUrl, Icon, formatDate, formatTime, translate as _ } from '@morawadigital/skynet-framework'
import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import { Alert, Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { convertOptions, getEditorConfig } from '../../util'
import MultiTextInput from '../controls/MultiTextInput'

class CustomMessageModal extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasSendingError:   false,
            isSending:         false,
            sendingSuccessful: false,

        }

    }

    errorSending() {

        this.setState( { hasSendingError: true } )

    }

    hide() {

        this.setState( {

            hasSendingError:   false,
            isSending:         false,
            sendingSuccessful: false,

        }, () => this.props.onHide() )

    }

    send() {

        this.setState( { hasSendingError: false, isSending: true, sendingSuccessful: false }, () => {

            const data = {

                notifiableGameIds: { __arr: true, values: this.props.games.map( e => e.Id ) },
                jsonMailList:      JSON.stringify( convertOptions( this.props.recipients ) ),
                htmlText:          this.props.content,
                token:             this.props.token,

            }

            ajax( getDataUrl( 'api/Schedule/sendCustomMessage' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { sendingSuccessful: true } ) : this.errorSending() )
                .catch( () => this.errorSending() )
                .finally( () => this.setState( { isSending: false } ) )

        } )

    }

    renderGame( game, i ) {

        return (

            <Badge key={ i } className='me-1 fs-6' bg='secondary'>

                { formatDate( game.ScheduleDate, { year: 'none', weekday: 'short' } ) }
                { ' - ' }
                { formatTime( game.ScheduleDate ) }
                { ' | ' }
                { this.renderTeamName( game.HomeTeam ) }
                { ' ' + _( 'vs.' ) + ' ' }
                { this.renderTeamName( game.AwayTeam ) }

            </Badge>

        )

    }

    renderTeamName( team ) {

        return team && <span title={ team.Name }>{ team.ShortName }</span>

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.hide() } backdrop='static' size='xl' scrollable>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Eigene Nachricht' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    {

                        this.state.sendingSuccessful ?

                            <Alert variant='success' className='my-5 text-center fs-3'><Icon icon='check' /> { _( 'Nachricht wurde gesendet.' ) }</Alert>

                        :

                            <>

                                <Row className='mb-2 align-items-center'>

                                    <Form.Label column sm={ 3 } lg={ 2 }>{ _( 'Betrifft' ) }:</Form.Label>

                                    <Col sm={ 9 } lg={ 10 }>

                                        { this.props.games.map( ( e, i ) => this.renderGame( e, i ) ) }

                                    </Col>

                                </Row>

                                <Form.Group as={ Row } className='mb-3 pb-3 border-bottom'>

                                    <Form.Label column sm={ 3 } lg={ 2 }>{ _( 'Empfänger' ) }:</Form.Label>

                                    <Col sm={ 9 } lg={ 10 }>

                                        <MultiTextInput
                                            disabled={ this.state.isSending }
                                            onChange={ e => this.props.onRecipientsChange( e ) }
                                            value={ this.props.recipients }
                                        />

                                    </Col>

                                </Form.Group>

                                <Editor
                                    disabled={ this.state.isSending }
                                    init={ getEditorConfig() }
                                    onEditorChange={ e => this.props.onContentChange( e ) }
                                    tinymceScriptSrc={ process.env.PUBLIC_URL + '/assets/vendor/tinymce/tinymce.min.js' }
                                />

                                { this.state.hasSendingError && <Alert variant='warning' className='mt-3'><Icon icon='exclamation-triangle' /> { _( 'Nachricht konnte nicht gesendet werden. Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                            </>

                    }

                </Modal.Body>

                <Modal.Footer>

                    {

                        this.state.sendingSuccessful ?

                            <Button onClick={ () => this.hide() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                        :

                            <>

                                <Button onClick={ () => this.hide() } disabled={ this.state.isSending } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                                <Button onClick={ () => this.send() } disabled={ this.props.recipients.length === 0 || ! this.props.content || this.state.isSending }>{ _( 'Senden' ) }</Button>

                            </>

                    }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default CustomMessageModal